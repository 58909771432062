import React from "react";
import { BuyRow } from "./style";
import Button from "../../../shared/controls/button";
import { getPriceFormatNumber } from "../../../shared/utils";
import SubscriptionInfoView from "../subscription/subscription-info-view";
import styled from "styled-components";

class PaymentsBuyRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getIdFromItem = (item) => {
    return item.id;
  }

  getQty = (item) => {
    if (item.editable) {
      let qty = `editable_${this.getIdFromItem(item)}` in this.state ? this.state[`editable_${this.getIdFromItem(item)}`] : item.id_secondary ? item.quantity : item.min_qty || 1;

      let items_found = (this.props.shoppingCart || []).filter( shoppingCartItem => shoppingCartItem.id === item.id );

      if (items_found && items_found.length > 0) {
        return items_found[0].quantity;
      }

      return (
        <AddContainerStyle>
          <div
            className={`subtract`}
            onClick={() => {
              if (qty > parseInt(item.min_qty || 1)) {
                this.setState({
                  [`editable_${this.getIdFromItem(item)}`]: qty - 1
                });
              }
            }}
          ><img alt="" src="/images/subtract_icon.png" /></div>

          <input
            name={`editable_${this.getIdFromItem(item)}`}
            type="number"
            value={qty}
            onChange={(e) => {
              this.setState({
                [`editable_${this.getIdFromItem(item)}`]: e.target.value
              });
            }}
            onBlur={(e) => {
              this.validEditable(e, item);
            }}
          />

          <div
            className={`add`}
            onClick={() => {
              this.setState({
                [`editable_${this.getIdFromItem(item)}`]: qty + 1
              });
            }}
          >
            <img alt="" src="/images/plus_icon.png" />
          </div>
        </AddContainerStyle>
      );
    }
    return item.qty;
  };

  getTotal = (item, item_found) => {
    let qty = 0;
    let price = 0;

    if (item.editable) {
      qty = this.state[`editable_${this.getIdFromItem(item)}`] || item_found?.quantity || item.min_qty || 1;
    } else {
      qty = item.qty;
    }

    if (item.wholesale_price && item.wholesale_qty && qty >= item.wholesale_qty) {
      price = item.wholesale_price;
    } else {
      price = item.price;
    }

    return qty * price;
  };

  getUnitPrice = (item, item_found) => {
    let qty = 0;

    if (item.editable) {
      qty = this.state[`editable_${this.getIdFromItem(item)}`] || item_found?.quantity || item.min_qty || 1;
    } else {
      qty = item.qty;
    }

    if (item.wholesale_price && item.wholesale_qty && qty >= item.wholesale_qty) {
      return item.wholesale_price;
    } else {
      return item.price;
    }
  }

  validEditable = (e, item) => {
    let value = e.target.value || 0;

    if (parseInt(value) < parseInt(item.min_qty || 1)) {
      this.setState({ [`editable_${this.getIdFromItem(item)}`]: parseInt(item.min_qty || 1) });
    } else {
      this.setState({ [`editable_${this.getIdFromItem(item)}`]: parseInt(value) });
    }
  };

  getCredits = (item) => {
    if (item.editable) {
      return parseInt(this.state.editable);
    }
    return item.qty;
  };

  buildJson = (item) => {
    let qty = 0;

    if (item.editable) {
      qty = this.state[`editable_${this.getIdFromItem(item)}`] || item.min_qty || 1;
    } else {
      qty = item.qty;
    }

    let jsonData = {
      id: item.id,
      title: [1,2,3,4].includes(item.id) ? `Paquete ${item.title} Orbibucks` : item.title,
      description: '',
      unit_price: item.price,
      price: this.getTotal(item, null),
      quantity: parseInt(qty),
      editable: item.editable,
      applicable_coupon: item.applicable_coupon,
      description_package: item.description,
      wholesale_price: item.wholesale_price,
      wholesale_qty: item.wholesale_qty,
      min_qty: item.min_qty
    };

    return jsonData;
  }

  addItemToShoppingCart = (item) => {

    let json = this.buildJson(item);

    if (this.props.addItemToShoppingCart) {
      this.props.addItemToShoppingCart(json);
    }
  }

  buildJsonPlans = () => {

    return({
      plans: [
        {
          id: 1,
          name: 'Public Basic',
          services_activated: [2,3,4,5,6,9,10,14],
          free: false,
          prices_description: []
        },
        {
          id: 2,
          name: 'Plan premium',
          services_activated: [2,3,4,5,6,7,9,10,11,12,13,14],
          price_plan_description: '$1990 USD',
          free: false,
          prices_description: []
        }
      ],
      services: [
        {
          id: 1,
          description: 'Catálogos de Datos Maestros:'
        },
        {
          id: 2,
          description: 'Usuarios'
        },
        {
          id: 3,
          description: 'Productos'
        },
        {
          id: 4,
          description: 'Instalaciones'
        },
        {
          id: 5,
          description: 'Red (Comunidad)'
        },
        {
          id: 6,
          description: 'Rutas'
        },
        {
          id: 7,
          description: 'Flotilla (normatividad)'
        },
        {
          id: 8,
          description: 'Controles:'
        },
        {
          id: 9,
          description: 'Órdenes y citas'
        },
        {
          id: 10,
          description: 'Embarques'
        },
        {
          id: 11,
          description: 'Tráfico & GPS'
        },
        {
          id: 12,
          description: 'Instalaciones'
        },
        {
          id: 13,
          description: 'Contratos'
        },
        {
          id: 14,
          description: 'Reportes: Inteligencia de Negocios'
        }
      ]
    });

  }

  buildMoreInfo = (item) => {
    if (item.id === 5 && this.state[`more_info_${this.getIdFromItem(item)}`]) {
      return(
        <div
          key={`more-info-${item.id}`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 20
          }}
        >
          <SubscriptionInfoView
            plansInfo={this.buildJsonPlans()}
          />
        </div>
      );
    }

    return '';
  }

  render() {
    const { data } = this.props;
    return data.map((item, index) => {
      // [1,2,3,4] hacen referencia a los ids de los paquetes de orbibucks, ya que no se pueden comprar mas de uno paquete por orden
      let is_disabled_button = false;
      let packages_orbibucks = (this.props.shoppingCart || []).filter( shoppingCartItem => [1,2,3,4].includes(shoppingCartItem.id));

      if (packages_orbibucks && packages_orbibucks.length > 0 && [1,2,3,4].includes(item.id)) {
        is_disabled_button = true;
      }

      let items_found = (this.props.shoppingCart || []).filter( shoppingCartItem => shoppingCartItem.id === item.id );

      return (
        <React.Fragment
          key={`buy-row-${index}`}
        >
          <BuyRow>
            <div 
              className="left"
              style={ item.id === 5 ? {cursor: 'pointer'} : {}}
              onClick={ () => {
                if (item.id === 5) {
                  this.setState({
                    [`more_info_${this.getIdFromItem(item)}`]: !(this.state[`more_info_${this.getIdFromItem(item)}`] ? true : false)
                  })
                }
              }}
            >
              {
                item.id === 5 && 
                <div className={`caret ${this.state[`more_info_${this.getIdFromItem(item)}`] ? 'open' : ''}`}>
                  <img
                    alt=""
                    src="/images/caret.svg"
                    width={"16px"}
                    height={"10px"}
                  />
                </div>
              }
              <div className="title">{[1,2,3,4].includes(item.id) ? `${item.title} Orbibucks` : `${item.title}`}</div>
              <div 
                className="subtitle"
                style={{
                  textAlign: 'center',
                  paddingLeft: 15,
                  paddingRight: 15
                }}
              >{item.description ? item.description : `Precio Unitario ${item.price} USD`}</div>
            </div>
            <div className="right">
              <div className="title">
                <div>QTY</div>
                <div>P.U</div>
                <div>Total</div>
                <div></div>
              </div>
              <div className="details">
                <div>{this.getQty(item)}</div>
                <div>{getPriceFormatNumber(this.getUnitPrice(item, items_found.length > 0 ? items_found[0] : null))}</div> 
                <div>{`${getPriceFormatNumber(this.getTotal(item, items_found.length > 0 ? items_found[0] : null))} USD`}</div> 
                <div>
                  {
                    items_found.length > 0 ?
                    <Button
                      text="Remover del carrito"
                      type="outline primary white"
                      onClick={() => {
                        if (this.props.removeFromShoppingCart) {
                          this.props.removeFromShoppingCart(item.id);
                        }
                      }}
                    /> :
                    <Button
                      text="Agregar a carrito"
                      type="primary"
                      disabled={is_disabled_button}
                      onClick={() => {this.addItemToShoppingCart(item);}}
                    />
                  }
                </div>
              </div>
            </div>
          </BuyRow>
          { this.buildMoreInfo(item) }
        </React.Fragment>
      );
    });
  }
}

export default PaymentsBuyRow;


export const AddContainerStyle = styled.div`
  display: flex;
  align-items: center;

  & .subtract {
    width: 20px; 
    height: 20px; 
    margin-right: 8px;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    & img {
      width: 20px;
      height: 20px;
    }
  }

  & .add {
    width: 20px; 
    height: 20px; 
    margin-left: 8px;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    & img {
      width: 20px;
      height: 20px;
    }
  }

`;