import React from "react";
import { Container, MainHeader, BuyContainer } from "./style";
import PaymentsBuyRow from "./buy/buy-row";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getEntityItems, readEntities, readEntity } from "../../redux/api";
import { addItemShoppingCart, getShoppingCartData, removeShoppingCart, removeItemShoppingCart, showSimpleFlashNotification } from "../../shared/utils";
import Button from '../../shared/controls/button';

class PaymentsBuy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iva: null,
      exchange_rate: null,
      currency_code: null,
      shopping_cart: getShoppingCartData()
    };
  }

  componentDidMount() {
    this.props.loadPackages({
      company_id: this.props.companyId
    });
    this.props.getCompany({
      company_id: this.props.companyId
    },{
      onSuccess: (r) => {
        this.setState({
          iva: parseFloat( (r && r.country && r.country.vat) ? r.country.vat : '0'),
          exchange_rate: parseFloat( (r && r.country && r.country.exchange_rate) ? r.country.exchange_rate : '0'),
          currency_code: (r && r.country && r.country.currency_code) ? r.country.currency_code : ''
        });
      }
    });
  }

  render() {
    return (
      <Container>
        <MainHeader>
          <div className="full">
            <div className="title">Comprar Orbibucks</div>
          </div>
        </MainHeader>
        <BuyContainer>
          <PaymentsBuyRow
            shoppingCart={this.state.shopping_cart}
            data={this.props.packages || []}
            iva={this.state.iva}
            addItemToShoppingCart={(item) => {
              addItemShoppingCart(item, () => {

                let message = '';

                if (item.id === 4) {
                  message = `Se agregó a tu carrito el Paquete ${item.quantity} Orbibucks`;
                } else if (item.id === 5) {
                  message = `Se agregó a tu carrito el paquete ${item.title} por ${item.quantity} ${item.quantity === 1 ? 'periodo' : 'periodos'}`;
                } else {
                  message = `Se agregó a tu carrito el paquete ${item.title}`;
                }

                showSimpleFlashNotification(message, null);

                this.setState({
                  shopping_cart: getShoppingCartData()
                });
              });
            }}
            removeFromShoppingCart={ item_id => {
              removeItemShoppingCart(item_id, () => {
                this.setState({
                  shopping_cart: getShoppingCartData()
                });
              });
            }}
          />
        </BuyContainer>
        
        <div
          style={{
            display: 'flex',
            width: '70%',
            marginLeft: '15%',
            marginBottom: 30,
            justifyContent: 'end',
            marginTop: 50
          }}
        >
          <Button
            text="Abrir carrito"
            type="primary"
            onClick={() => {
              this.props.history.push('/company/payments/buy/shopping', { iva: this.state.iva, exchange_rate: this.state.exchange_rate, currency_code: this.state.currency_code });
            }}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {

  const companyId = state.globalCatalog.session.company.id;
  const packages = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.ORBIBUCKSCATALOG") || [];

  return {
    companyId,
    packages
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompany: (params, opt) => dispatch(readEntity("COMPANIES.RETRIEVE", params, opt)),
    loadPackages: (params, opt) => dispatch(readEntities("COMPANIES.SUSCRIPTIONS.ORBIBUCKSCATALOG", params, opt))
  }
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsBuy));
