import { getApiUrl, BACKEND, postApiUrl, deleteApiUrl, patchApiUrl, interpolate } from "../../shared/backend-api";
import store from "../store";


const ACTIONS = {
  USERS: {
    HISTORICAL: {
      REQUEST: {
        TYPE: 'USERS_HISTORICAL_REQUEST',
        ACTION: () => ({
          type: ACTIONS.USERS.HISTORICAL.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: 'USERS_HISTORICAL_SUCCESS',
        ACTION: response => ({
          type: ACTIONS.USERS.HISTORICAL.SUCCESS.TYPE,
          response
        })
      },
      ERROR: {
        TYPE: 'USERS_HISTORICAL_ERROR',
        ACTION: error => ({
          type: ACTIONS.USERS.HISTORICAL.ERROR.TYPE,
          error
        })
      }
    },
    LIST: {
      REQUEST: {
        TYPE: 'USERS_LIST_REQUEST',
        ACTION: () => ({
          type: ACTIONS.USERS.LIST.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: 'USERS_LIST_SUCCESS',
        ACTION: response => ({
          type: ACTIONS.USERS.LIST.SUCCESS.TYPE,
          response
        })
      },
      ERROR: {
        TYPE: 'USERS_LIST_ERROR',
        ACTION: error => ({
          type: ACTIONS.USERS.LIST.ERROR.TYPE,
          error
        })
      }
    },
    GET: {
      REQUEST: {
        TYPE: 'USERS_GET_REQUEST',
        ACTION: () => ({
          type: ACTIONS.USERS.GET.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: 'USERS_GET_SUCCESS',
        ACTION: response => ({
          type: ACTIONS.USERS.GET.SUCCESS.TYPE,
          response
        })
      },
      ERROR: {
        TYPE: 'USERS_GET_ERROR',
        ACTION: error => ({
          type: ACTIONS.USERS.GET.ERROR.TYPE,
          error
        })
      }
    },
    GET_BY_EMAIL: {
      REQUEST: {
        TYPE: 'USERS_GET_BY_EMAIL_REQUEST',
        ACTION: () => ({
          type: ACTIONS.USERS.GET_BY_EMAIL.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: 'USERS_GET_BY_EMAIL_SUCCESS',
        ACTION: response => ({
          type: ACTIONS.USERS.GET_BY_EMAIL.SUCCESS.TYPE,
          response
        })
      },
      ERROR: {
        TYPE: 'USERS_GET_BY_EMAIL_ERROR',
        ACTION: error => ({
          type: ACTIONS.USERS.GET_BY_EMAIL.ERROR.TYPE,
          error
        })
      }
    },
    TYPES: {
      LIST: {
        REQUEST: {
          TYPE: 'USERS_TYPE_LIST_REQUEST',
          ACTION: () => ({
            type: ACTIONS.USERS.TYPES.LIST.REQUEST.TYPE
          })
        },
        SUCCESS: {
          TYPE: 'USERS_TYPE_LIST_SUCCESS',
          ACTION: response => ({
            type: ACTIONS.USERS.TYPES.LIST.SUCCESS.TYPE,
            response
          })
        },
        ERROR: {
          TYPE: 'USERS_TYPE_LIST_ERROR',
          ACTION: error => ({
            type: ACTIONS.USERS.TYPES.LIST.ERROR.TYPE,
            error
          })
        }
      }
    },
    SEARCH_BY_TEXT: {
      TYPE: 'USERS_SEARCH_BY_TEXT',
      ACTION: (searchStr) => ({
        type: ACTIONS.USERS.SEARCH_BY_TEXT.TYPE,
        searchStr
      })
    },
    PERMISSIONS: {
      LIST: {
        REQUEST: {
          TYPE: 'ACTIONS_USERS_PERMISSIONS_LIST_REQUEST',
          ACTION: () => ({
            type: ACTIONS.USERS.PERMISSIONS.LIST.REQUEST.TYPE
          })
        },
        SUCCESS: {
          TYPE: 'ACTIONS_USERS_PERMISSIONS_LIST_SUCCESS',
          ACTION: (response) => ({
            type: ACTIONS.USERS.PERMISSIONS.LIST.SUCCESS.TYPE,
            response
          })
        },
        ERROR: {
          TYPE: 'ACTIONS_USERS_PERMISSIONS_LIST_ERROR',
          ACTION: (error) => ({
            type: ACTIONS.USERS.PERMISSIONS.LIST.ERROR.TYPE,
            error
          })
        },
      },
      // POST: {
      //   REQUEST: {
      //     TYPE: 'ACTIONS_USERS_PERMISSIONS_POST_REQUEST',
      //     ACTION: () => ({
      //       type: ACTIONS.USERS.PERMISSIONS.POST.REQUEST.TYPE
      //     })
      //   },
      //   SUCCESS: {
      //     TYPE: 'ACTIONS_USERS_PERMISSIONS_POST_SUCCESS',
      //     ACTION: (response) => ({
      //       type: ACTIONS.USERS.PERMISSIONS.POST.SUCCESS.TYPE,
      //       response
      //     })
      //   },
      //   ERROR: {
      //     TYPE: 'ACTIONS_USERS_PERMISSIONS_POST_ERROR',
      //     ACTION: (error) => ({
      //       type: ACTIONS.USERS.PERMISSIONS.POST.ERROR.TYPE,
      //       error
      //     })
      //   },
      // }
    },
    TAGS: {
      USER_TAGS: {
        LIST: {
          REQUEST: {
            TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.USERS.TAGS.USER_TAGS.LIST.REQUEST.TYPE
            })
          },
          SUCCESS: {
            TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.USERS.TAGS.USER_TAGS.LIST.SUCCESS.TYPE,
              response
            })
          },
          ERROR: {
            TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.USERS.TAGS.USER_TAGS.LIST.ERROR.TYPE,
              error
            })
          },
        },
        FLEETS: {
          LIST: {
            REQUEST: {
              TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_FLEETS_LIST_REQUEST",
              ACTION: () => ({
                type: ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.REQUEST.TYPE
              })
            },
            SUCCESS: {
              TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_FLEETS_LIST_SUCCESS",
              ACTION: (response) => ({
                type: ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.SUCCESS.TYPE,
                response
              })
            },
            ERROR: {
              TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_FLEETS_LIST_ERROR",
              ACTION: (error) => ({
                type: ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.ERROR.TYPE,
                error
              })
            },
          }
        }
        // POST: {
        //   REQUEST: {
        //     TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_POST_REQUEST",
        //     ACTION: () => ({
        //       type: ACTIONS.USERS.TAGS.USER_TAGS.POST.REQUEST.TYPE
        //     })
        //   },
        //   SUCCESS: {
        //     TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_POST_SUCCESS",
        //     ACTION: () => ({
        //       type: ACTIONS.USERS.TAGS.USER_TAGS.POST.SUCCESS.TYPE
        //     })
        //   },
        //   ERROR: {
        //     TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_POST_ERROR",
        //     ACTION: () => ({
        //       type: ACTIONS.USERS.TAGS.USER_TAGS.POST.ERROR.TYPE
        //     })
        //   },
        // },
        // DELETE: {
        //   REQUEST: {
        //     TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_DELETE_REQUEST",
        //     ACTION: () => ({
        //       type: ACTIONS.USERS.TAGS.USER_TAGS.DELETE.REQUEST.TYPE
        //     })
        //   },
        //   SUCCESS: {
        //     TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_DELETE_SUCCESS",
        //     ACTION: () => ({
        //       type: ACTIONS.USERS.TAGS.USER_TAGS.DELETE.SUCCESS.TYPE
        //     })
        //   },
        //   ERROR: {
        //     TYPE: "ACTIONS_USERS_TAGS_USER_TAGS_DELETE_ERROR",
        //     ACTION: () => ({
        //       type: ACTIONS.USERS.TAGS.USER_TAGS.DELETE.ERROR.TYPE
        //     })
        //   },
        // }
      }
    }
  }
}

const initialState = {
  users: {
    isFetching: false,
    error: '',
    data: {}
  },
  user: {
    isFetching: false,
    error: '',
    data: {}
  },
  userTypes: {
    isFetching: false,
    data: [],
    error: ''
  },
  userPermissions: {
    isFetching: false,
    data: [],
    error: {}
  },
  userTags: {
    isFetching: false,
    data: [],
    error: ''
  },
  userFleetTags: {
    isFetching: false,
    data: [],
    error: ''
  },
  searchedUser: {
    isFetching: false,
    error: '',
    data: []
  },
  searchStr: '',
  historical: {
    isFetching: false,
    error: '',
    data: []
  }
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.USERS.GET_BY_EMAIL.REQUEST.TYPE:
      return Object.assign({}, state, {
        searchedUser: {
          isFetching: true
        }
      });
    case ACTIONS.USERS.GET_BY_EMAIL.SUCCESS.TYPE:
      return Object.assign({}, state, {
        searchedUser: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.GET_BY_EMAIL.ERROR.TYPE:
      return Object.assign({}, state, {
        searchedUser: {
          isFetching: false,
          error: action.error
        }
      });
    case ACTIONS.USERS.SEARCH_BY_TEXT.TYPE:
      return Object.assign({}, state, {
        searchStr: action.searchStr
      });
    case ACTIONS.USERS.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        user: {
          isFetching: true,
          data: {}
        }
      });
    case ACTIONS.USERS.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        user: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        user: {
          isFetching: false,
          data: {},
          error: action.error
        }
      });
    case ACTIONS.USERS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        users: {
          isFetching: true,
          data: {}
        }
      });
    case ACTIONS.USERS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        users: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        users: {
          isFetching: false,
          data: state.users.data,
          error: action.error
        }
      });
    case ACTIONS.USERS.HISTORICAL.REQUEST.TYPE:
      return Object.assign({}, state, {
        historical: {
          isFetching: true,
          data: {}
        }
      });
    case ACTIONS.USERS.HISTORICAL.SUCCESS.TYPE:
      return Object.assign({}, state, {
        historical: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.HISTORICAL.ERROR.TYPE:
      return Object.assign({}, state, {
        historical: {
          isFetching: false,
          data: state.users.data,
          error: action.error
        }
      });
    case ACTIONS.USERS.TYPES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        userTypes: {
          isFetching: true,
          data: []
        }
      });
    case ACTIONS.USERS.TYPES.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        userTypes: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.TYPES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        userTypes: {
          isFetching: false,
          data: state.users.data,
          error: action.error
        }
      });
    
    case ACTIONS.USERS.PERMISSIONS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        userPermissions: {
          isFetching: true,
          data: []
        }
      });
    case ACTIONS.USERS.PERMISSIONS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        userPermissions: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.PERMISSIONS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        userPermissions: {
          isFetching: false,
          data: [],
          error: action.error
        }
      });

    case ACTIONS.USERS.TAGS.USER_TAGS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        userTags: {
          isFetching: true,
          data: []
        }
      });
    case ACTIONS.USERS.TAGS.USER_TAGS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        userTags: {
          isFetching: false,
          data: action.response
        }
      });
    case ACTIONS.USERS.TAGS.USER_TAGS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        userTags: {
          isFetching: false,
          data: [],
          error: action.error
        }
      });
      case ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.REQUEST.TYPE:
        return Object.assign({}, state, {
          userFleetTags: {
            isFetching: true,
            data: []
          }
        });
      case ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.SUCCESS.TYPE:
        return Object.assign({}, state, {
          userFleetTags: {
            isFetching: false,
            data: action.response
          }
        });
      case ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.ERROR.TYPE:
        return Object.assign({}, state, {
          userFleetTags: {
            isFetching: false,
            data: [],
            error: action.error
          }
        });
    default:
      return state;
  }
}

export const getUserInfo = (company_id, user_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.USERS.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.USERS.DATA.GET, {company_id, user_id})
      .then(
        response => response.json(),
      )
      .then(response =>
        dispatch(ACTIONS.USERS.GET.SUCCESS.ACTION(response))
      )
      .catch(
        error => error.then(e => dispatch(ACTIONS.USERS.GET.ERROR.ACTION(e)))
      )
  } 
}

export const getUsers = (limit = 10, offset = 1, userType = '', facilities = '', roles = '', account_status = '', text = '') => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.USERS.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.USERS.DATA.LIST, { company_id, limit, offset, userType, facilities, roles, account_status, text })
      .then(
        response => response.json(),
      )
      .then(response =>
        dispatch(ACTIONS.USERS.LIST.SUCCESS.ACTION(response))
      )
      .catch(
        error => error.then(e => dispatch(ACTIONS.USERS.LIST.ERROR.ACTION(e)))
      )
  };
};

export const getHistoricalUsers = (limit = 10, offset = 1, userType = '', facilities = '', roles = '', account_status = '', text = '', start_date = '', end_date = '') => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.USERS.HISTORICAL.REQUEST.ACTION()); 
    return getApiUrl(BACKEND.USERS.HISTORICAL.LIST, { company_id, limit, offset, userType, facilities, roles, account_status, text, start_date, end_date })
      .then(
        response => response.json(),
      )
      .then(response =>
        dispatch(ACTIONS.USERS.HISTORICAL.SUCCESS.ACTION(response))
      )
      .catch(
        error => error.then(e => dispatch(ACTIONS.USERS.HISTORICAL.ERROR.ACTION(e)))
      )
  };
};

export const getHistoricalUsersDownload = (limit = 10, offset = 1, userType = '', facilities = '', roles = '', account_status = '', text = '', start_date = '', end_date = '') => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return 'api/' + interpolate(BACKEND.USERS.HISTORICAL.DOWNLOAD, { company_id, limit, offset, userType, facilities, roles, account_status, text, start_date, end_date });
};

export const getUserTypes = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.USERS.TYPES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY_CATALOG.USER_TYPE, {company_id})
      .then(
        response => response.json(),
      )
      .then(response =>
        dispatch(ACTIONS.USERS.TYPES.LIST.SUCCESS.ACTION(response))
      )
      .catch(
        error => error.then(e => dispatch(ACTIONS.USERS.TYPES.LIST.ERROR.ACTION(e)))
      )
  };
}

export const getUserByEmail = (email, invitation, company_id, is_from_recommendations) => {
  return function (dispatch) {
    dispatch(ACTIONS.USERS.GET_BY_EMAIL.REQUEST.ACTION(email));
    return getApiUrl(BACKEND.USERS.DATA.GET_BY_EMAIL, { email, invitation, company_id, from_recommendations: is_from_recommendations ? '1' : '0' })
      .then(
        response => response.json(),
      )
      .then(response =>
        dispatch(ACTIONS.USERS.GET_BY_EMAIL.SUCCESS.ACTION(response))
      )
      .catch(
        error => error.then(e => dispatch(ACTIONS.USERS.GET_BY_EMAIL.ERROR.ACTION(e)))
      )
  };
};

export const filterUsersByText = (searchStr) => {
  return function (dispatch) {
    dispatch(ACTIONS.USERS.SEARCH_BY_TEXT.ACTION(searchStr));
  };
};

export const getUserPermissions = (user_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.USERS.PERMISSIONS.LIST.REQUEST.ACTION())
    return getApiUrl( BACKEND.USERS.PERMISSIONS.LIST, {company_id, user_id})
      .then(response => response.json())
      .then(data => dispatch(ACTIONS.USERS.PERMISSIONS.LIST.SUCCESS.ACTION(data)))
      .catch(error => dispatch(ACTIONS.USERS.PERMISSIONS.LIST.ERROR.ACTION(error)))
  }
}

export const setUserPermissions = (user_id, permissionsMatrix) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function () {
    return postApiUrl( BACKEND.USERS.PERMISSIONS.POST, {company_id, user_id, permissions: permissionsMatrix})
      .then(response => response.json())
      .then(data => data)
      .catch(error => false)
  }
}

export const getUserTags = (user_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.USERS.TAGS.USER_TAGS.LIST.REQUEST.ACTION())
    return getApiUrl( BACKEND.COMPANY.USERS.TAG.LIST.USER, {company_id, user_id})
      .then(
        response => response.json()
      )
      .then(
        data => dispatch(ACTIONS.USERS.TAGS.USER_TAGS.LIST.SUCCESS.ACTION(data))
      )
      .catch(
        error => dispatch(ACTIONS.USERS.TAGS.USER_TAGS.LIST.ERROR.ACTION(error))
      )
  }
}

export const getUserFleetTags = (user_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.REQUEST.ACTION())
    return getApiUrl( BACKEND.COMPANY.USERS.TAG.LIST.FLEET, {company_id, user_id})
      .then(
        response => response.json()
      )
      .then(
        data => dispatch(ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.SUCCESS.ACTION(data))
      )
      .catch(
        error => dispatch(ACTIONS.USERS.TAGS.USER_TAGS.FLEETS.LIST.ERROR.ACTION(error))
      )
  }
}

export const addTagToUser = (user_id, tag_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function () {
    return postApiUrl( BACKEND.COMPANY.USERS.TAG.POST, {company_id, user_id, tags: tag_id})
      .then(
        response => response.json()
      )
      .then(
        data => true
      )
      .catch(
        error => false
      )
  }
}

export const editTagFromUser = (user_id, new_tag_id, user_tag_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function () {
    return patchApiUrl( BACKEND.COMPANY.USERS.TAG.PATCH, {company_id, user_id, user_tag_id, tags: new_tag_id})
      .then(
        response => response.json()
      )
      .then(
        response => true
      )
      .catch(
        error => false
      )
  }
}

export const removeTagFromUser = (user_id, user_tag_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function () {
    return deleteApiUrl( BACKEND.COMPANY.USERS.TAG.DELETE, {company_id, user_id, user_tag_id})
      .then(
        response => response.json()
      )
      .then(
        response => true
      )
      .catch(
        error => false
      )
  }
}

