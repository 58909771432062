import React from "react";
import Button from "../../../shared/controls/button";
import { getPriceFormatNumber } from "../../../shared/utils";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Loader from "../../../shared/icons/tat/loader";
import OptionsList from '../../../shared/component/options-list';
import moment from 'moment';

class SubscriptionShoppingCarView extends React.Component {

  constructor() {
    super();

    this.state = {
      isCouponsOpen: false
    }
  }

  // Props que acepta el componente
  // {
  //   editable: boolean,
  //   itemsToBuy: [
  //     {
  //       id: Number,
  //       title: String,
  //       description: String,
  //       price: Number,
  //       quantity: Number,
  //     }
  //   ]
  // }

  
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    if (`${event.target.className}` !== 'option-list-component') {
      this.setState({isCouponsOpen: false});
    }
  }

  buildCouponsComponent = () => {
    return(
      <div
        style={this.props.data ? {marginLeft: 50, fontSize: 13, display: 'flex', width: 300, alignItems: 'center', position: 'relative'} : {marginLeft: 50, fontSize: 13, display: 'flex', width: 300, alignItems: 'center', cursor: 'pointer', position: 'relative'}}
        onClick={() => this.setState({isCouponsOpen: !this.state.isCouponsOpen})}
      >
        <div style={{zIndex: 99999}}>
          <div style={{opacity: '59%'}}>
            {'Cupones:'}
          </div>
          <OptionsList
            width={'600px'}
            visible={this.state.isCouponsOpen}
            loading={false}
            items={(this.props.coupons || []).map( item => {
              return {
                item,
                component: 
                  <div className="option-list-component">
                    <div className="option-list-component">{`Cupón por haber recomendado a ${item.receiver_user?.username || ''} de la empresa ${item.company?.orbi_id || ''}`}</div>
                    <div className="option-list-component">{`Descuento por ${ item.discount * 100 }%`}</div>
                    <div className="option-list-component">{`vigente del ${item.validity_start ? moment(item.validity_start).format('DD/MMM/YYYY') : '---'} al ${moment(item.validity).format('DD/MMM/YYYY')}`}</div>
                  </div>
              }
            })}
            onClick={(value) => {
              if (this.props.onCouponSelect) {
                this.props.onCouponSelect(value);
              }
              this.setState({isCouponsOpen: false});
            }}
          />
        </div>

        <div
          style={{
            borderBottom: '2px solid rgba(255, 255, 255, 0.59)',
            height: 15,
            width: '100%',
            marginLeft: 8,
            marginRight: 8,
            textAlign: 'center',
            opacity: '59%'
          }}
        >
          {this.props.couponSelected ? `Cupón por ${this.getCouponDiscount() * 100 }%` : ''}
        </div>
        {
          this.props.data ? '' :
          this.props.couponSelected &&
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (this.props.onCouponSelect) {
                this.props.onCouponSelect(null);
              }
            }}
          >
            <img src="/images/close.svg" alt="" style={{width: 15, height: 15, cursor: 'pointer', marginLeft: 3, marginRight: 8}}/>
          </div>
        }
        {
          this.props.data ? '' :
          <img
            alt=""
            src="/images/select-arrow.svg"
            width={"15px"}
            height={"12px"}
          />
        }
      </div>
    );
  }

  buildTitle = () => {

    let exchange_rate = this.props.data ? (this.props.data.general_data?.exchange_rate || 0) : this.props.exchange_rate;

    return(
      <TitleView>
        <div>{'Carrito de compra'}
        </div>

        {this.buildCouponsComponent()}

        <div></div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              fontSize: 13
            }}
          >{'TIPO DE CAMBIO USD'}</div>
          <div
            style={{
              fontSize: 17,
              backgroundColor: '#35435D',
              width: 'fit-content',
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 3,
              paddingBottom: 3,
              marginTop: 4
            }}
          >{ `${getPriceFormatNumber(exchange_rate)}` }</div>
        </div> */}
        
      </TitleView>
    );
  }

  buildListHeader = () => {
    let isEditable = this.props.editable;

    let iva = this.props.data ? (this.props.data.general_data?.iva || 0) : this.props.iva;

    let discount = this.getCouponDiscount();

    if (discount > 0) {
      discount = discount * 100;
    }

    return(
      <HeaderContainerView>
        <ItemDescriptionContainerView
          isEditable={isEditable}
        />

        {
          isEditable ?
          this.buildQuantityHeader() : <></>
        }

        {
          this.props.couponSelected &&
          <>
            <QuantityItemContainer
              data-tip
              data-for={`dcto-tooltip`}
            >
              <div>{'DCTO'}</div>
              <div>{`${discount}%`} </div>
              <img 
                src="/images/menu/Help.png" 
                alt="" 
                class="icon" 
                style={{height: 16, minHeight: 16, width: 16, position: 'absolute', left: 'calc(50% + 20px)', top: 0}}
                />
            </QuantityItemContainer>

            <ReactTooltip
              id={'dcto-tooltip'}
              getContent={() => `Descuento ${discount}%`}
              delayHide={200}
              place={"top"}
              border={true}
              type={"light"}
              effect="solid"
            />

            <QuantityItemContainer
              data-tip
              data-for={`pu-dcto-tooltip`}
            >
              <div>{'P.U. DCTO'}</div>
              <div>{`${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`} </div>
              <img 
                src="/images/menu/Help.png" 
                alt="" 
                class="icon" 
                style={{height: 16, minHeight: 16, width: 16, position: 'absolute', left: 'calc(50% + 28px)', top: 0}}
                />
            </QuantityItemContainer>

            <ReactTooltip
              id={'pu-dcto-tooltip'}
              getContent={() => `Precio Unitario con Descuento ${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`}
              delayHide={200}
              place={"top"}
              border={true}
              type={"light"}
              effect="solid"
            />
          </>
        }

        {/* <QuantityItemContainer>
          <div>{`IVA ${iva * 100}%`}</div>
          <div>{`${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`}</div>
        </QuantityItemContainer> */}

        <QuantityItemContainer>
          <div>{`Sub Total`}</div>
          <div>{`${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`}</div>
        </QuantityItemContainer>

        {
          this.props.data ? '' :
          <ItemDescriptionDeleteView
            isEditable={isEditable}
          />
        }

      </HeaderContainerView>
    );
  }

  buildQuantityHeader = () => {
    return(
      <>
        <QuantityItemContainer>
          {'QTY'}
        </QuantityItemContainer>
        <>
          <QuantityItemContainer
            data-tip
            data-for={`pu-usd-tooltip`}
          >
            <div>{'P.U.'}</div>
            <div>{'USD'}</div>
            <img 
              src="/images/menu/Help.png" 
              alt="" 
              class="icon" 
              style={{height: 16, minHeight: 16, width: 16, position: 'absolute', left: 'calc(50% + 18px)', top: 0}}
              />
          </QuantityItemContainer>

          <ReactTooltip
            id={'pu-usd-tooltip'}
            getContent={() => 'Precio Unitario USD'}
            delayHide={200}
            place={"top"}
            border={true}
            type={"light"}
            effect="solid"
          />
        </>

        <>
          <QuantityItemContainer
            data-tip
            data-for={`tc-tooltip`}
          >
            {'T.C'}
            <img 
              src="/images/menu/Help.png" 
              alt="" 
              class="icon" 
              style={{height: 16, minHeight: 16, width: 16, position: 'absolute', left: 'calc(50% + 18px)', top: 0}}
              />
          </QuantityItemContainer>
          <ReactTooltip
            id={'tc-tooltip'}
            getContent={() => 'Tipo de Cambio'}
            delayHide={200}
            place={"top"}
            border={true}
            type={"light"}
            effect="solid"
          />
        </>

        <>
          <QuantityItemContainer
            data-tip
            data-for={`pu-tooltip`}
          >
            <div>{'P.U.'}</div>
            <div>{`${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`}</div>
            <img 
              src="/images/menu/Help.png" 
              alt="" 
              class="icon" 
              style={{height: 16, minHeight: 16, width: 16, position: 'absolute', left: 'calc(50% + 18px)', top: 0}}
              />
          </QuantityItemContainer>
          <ReactTooltip
            id={'pu-tooltip'}
            getContent={() => `Precio Unitario ${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`}
            delayHide={200}
            place={"top"}
            border={true}
            type={"light"}
            effect="solid"
          />
        </>


      </>
    );
  }

  buildListOfItems = () => {

    let services = this.props.walletOrder ? this.props.walletOrder.movements : this.props.itemsToBuy || [];

    return(
      <ListOfItemsContainerView>
        {
          services.map((item, index) => {
            return(
              this.buildItem(item, index)
            );
          })
        }
      </ListOfItemsContainerView>
    );
  }

  deleteItem = (id) => {
    if (this.props.deleteItem) {
      this.props.deleteItem(id);
    }
  }

  getCouponDiscount = () => {

    if (this.props.walletOrder) {

      for (let i = 0; i < (this.props.walletOrder.movements || []).length; i++ ) {
        if (this.props.walletOrder.movements[i].discount_percentage) {
          return parseFloat(this.props.walletOrder.movements[i].discount_percentage);
        }
      }

    }

    if (this.props.couponSelected) {

      if (this.props.couponSelected.discount > 0 && (this.props.couponSelected?.status?.id || 0) !== 3) {
        return parseFloat(this.props.couponSelected.discount);
      }

      if ((this.props.couponSelected.discount_applied || 0) > 0) {
        return parseFloat(this.props.couponSelected.discount_applied);
      }

    }

    return 0;

  }

  buildItemName = (data) => {

    if ('started_date' in data && 'finished_date' in data) {
      return `${data.package_name || ''}: Vigencia ${data.started_date} - ${data.finished_date}`;
    } else {
      return data.package_name || '';
    }

  }

  buildItem = (item, index) => {
    let iva_value = parseFloat(this.props.data ? (this.props.data.general_data?.iva || 0) : this.props.iva);
    let exchange_rate = parseFloat(this.props.data ? (this.props.data.general_data?.exchange_rate || 0) : this.props.exchange_rate);

    let isEditable = this.props.editable;
    let first_price = 0;

    // Se obtiene el precio unitario, si tiene precio de mayoreo
    if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
      first_price = item.wholesale_price;
    } else {
      first_price = item.unit_price;
    }

    let discount_coupon = this.getCouponDiscount();

    let amount = this.props.walletOrder ? parseFloat(item.amount) : parseFloat(first_price * exchange_rate).toFixed(2);
    let discount_back = (discount_coupon > 0) ? parseFloat(amount * discount_coupon).toFixed(2) : 0;
    let amount_pay = this.props.walletOrder ? parseFloat(item.amount_payment) : (amount - discount_back).toFixed(2);

    let subtotal_back = this.props.data ? parseFloat(item.subtotal) : (amount_pay * item.quantity).toFixed(2);
    // let amount_pay_with_iva = (amount_pay * iva_value).toFixed(2);    

    return(
      <ItemContainerView
        key={`item-to-buy-${index}`}
      >

        <ItemDescriptionContainerView
          isEditable={isEditable}
        > 
          <ItemDescriptionNameView>{`${ this.props.data && item.data ? this.buildItemName(item.data) : item.title}`}</ItemDescriptionNameView>
          <ItemDescriptionPlanDescriptionView>{item.description}</ItemDescriptionPlanDescriptionView>
        </ItemDescriptionContainerView>

        {
          isEditable ?
          this.buildQuantityItem(item) : <></>
        }

        {
          this.props.couponSelected &&
          <>
            <QuantityItemContainer>
              {`${getPriceFormatNumber(discount_back)}`}
            </QuantityItemContainer>
            <QuantityItemContainer>
              {`${getPriceFormatNumber(amount_pay)}`}
            </QuantityItemContainer>
          </>
        }

        {/* <QuantityItemContainer>
          {`${getPriceFormatNumber(amount_pay_with_iva)}`}
        </QuantityItemContainer> */}

        <ItemDescriptionPricePlanView
          isEditable={isEditable}
        >
          {`${getPriceFormatNumber(subtotal_back)}`}
        </ItemDescriptionPricePlanView>
        
        {
          this.props.data ? '' : 
          <ItemDescriptionDeleteView
            isEditable={isEditable}
          >
            <img 
              alt='delete' 
              src='/images/delete.svg'
              style={{
                cursor: 'pointer'
              }}
              onClick={ () => { this.deleteItem(item.id) }}/>
          </ItemDescriptionDeleteView>
        }

      </ItemContainerView>
    );
  }

  validEditable = (e, id, validation, min_qty) => {

    let newValue = e.target.value;

    if (parseInt(newValue) < min_qty && validation) {
      newValue = min_qty;
    }

    let items = this.props.itemsToBuy || [];

    items.map( (item, index) => {
      if (item.id === id) {
        items[index].quantity = newValue;
      }
    })

    this.props.changeEditable(items);
  };

  buildQuantityItem = (item) => {

    let unit_price = this.props.data ? parseFloat(item.price_list_usd) : item.unit_price;

    if (this.props.data) {
      unit_price = parseFloat(item.price_list_usd);
    } else {
      if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
        unit_price = item.wholesale_price;
      } else {
        unit_price = item.unit_price;
      }
    }

    let amount = this.props.data ? parseFloat(item.amount) : (unit_price * this.props.exchange_rate).toFixed(2);

    let exchange_rate = this.props.data ? (this.props.data.general_data?.exchange_rate || 0) : this.props.exchange_rate;
    let qty = parseInt(item.quantity);

    return(
      <>
        <QuantityItemContainer>
          <QuantityItemInput
            name="editable"
            type="number"
            readOnly={!item.editable}
            value={qty}
            onChange={(e) => {
              if (item.editable)
                this.validEditable(e, item.id, false, item.min_qty);
            }}
            onBlur={(e) => {
              if (item.editable)
                this.validEditable(e, item.id, true, item.min_qty);
            }}
          />
        </QuantityItemContainer>

        <QuantityItemContainer>
          <QuantityItemPrice>
            {`${getPriceFormatNumber(unit_price)}`}
          </QuantityItemPrice>
        </QuantityItemContainer>

        <QuantityItemContainer>
          <QuantityItemPrice>
            {`${getPriceFormatNumber(exchange_rate)}`}
          </QuantityItemPrice>
        </QuantityItemContainer>

        <QuantityItemContainer>
          <QuantityItemPrice>
            {`${getPriceFormatNumber(amount)}`}
          </QuantityItemPrice>
        </QuantityItemContainer>
      </>
    );
  }

  buildBuyInfo = () => {

    let services = this.props.itemsToBuy || [];
    let services_to_buy = [];
    let subtotal = 0.0;
    let iva = 0.0;
    let total = 0.0;
    let exchange_rate = parseFloat(this.props.data ? (this.props.data.general_data?.exchange_rate || 0) : this.props.exchange_rate);

    if (this.props.data) {

      subtotal = this.props.walletOrder ? parseFloat(this.props.walletOrder.subtotal) : 0;
      iva = this.props.walletOrder ? parseFloat(this.props.walletOrder.vat) : 0;
      total = this.props.walletOrder ? parseFloat(this.props.walletOrder.total) : 0;

    } else {

      let discount_coupon = this.getCouponDiscount();

      let price_value = services.map(item => {
        let first_price = 0;

        if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
          first_price = item.wholesale_price;
        } else {
          first_price = item.unit_price;
        }

        let amount = parseFloat(first_price * exchange_rate).toFixed(2);
        let discount_back = (discount_coupon > 0) ? parseFloat(amount * discount_coupon).toFixed(2) : 0;
        let amount_pay = (amount - discount_back).toFixed(2);

        return (amount_pay * item.quantity).toFixed(2);

      }).reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2), 0);

      subtotal = parseFloat(price_value).toFixed(2);
      iva = parseFloat(subtotal * this.props.iva).toFixed(2);
      total = (parseFloat(subtotal) + parseFloat(iva)).toFixed(2);
  
      services_to_buy = services.map(item => {
  
        let service_temp = {
          package_id: item.id,
          quantity: parseInt(item.quantity),
          package_name: item.title
        };
  
        // if (item.applicable_coupon && this.props.couponSelected) {
        //   service_temp.coupon_id = this.props.couponSelected.id;
        // }
  
        return service_temp;
      });

    }

    return(
      <BuyInfoContainerView>

        <BuyInfoPriceContainerView>
          <BuyInfoPriceTotalView>{'SubTotal'}</BuyInfoPriceTotalView>
          <BuyInfoPriceView>{`${getPriceFormatNumber(subtotal)} ${this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code}`}</BuyInfoPriceView>
        </BuyInfoPriceContainerView>

        <BuyInfoPriceContainerView
          style={{ marginTop: -15 }}
        >
          <div style={{fontSize: 18}}>{'IVA'}</div>
          <div style={{fontSize: 18}}>{`${getPriceFormatNumber(iva)} ${this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code}`}</div>
        </BuyInfoPriceContainerView>

        <DividerView/>

        <BuyInfoPriceContainerView>
          <BuyInfoPriceTotalView>{'Total'}</BuyInfoPriceTotalView>
          <BuyInfoPriceView>{`${getPriceFormatNumber(total)} ${this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code}`}</BuyInfoPriceView>
        </BuyInfoPriceContainerView>

        {
          this.props.content && this.props.content
        }

        {
          this.props.data ? '' :
          <ButtonContainer>
            <ReactTooltip
              id={"tooltip-error-invoice_data"}
              place="top"
              type="light"
              effect="solid"
              html={true}
              event="mouseover"
              eventOff="mouseleave"
            />
            <Button
              text={this.props.isLoading ? <Loader/> : "Crear Pedido"}
              type="primary"
              disabled={(this.props.itemsToBuy === null) || (this.props.itemsToBuy.length === 0) || this.props.isLoading ||(this.props.buyButtonDisabled) || (this.props.errors || '') !== ""}
              onClick={() => {
                if (this.props.buyAction) {
                  this.props.buyAction({
                    items: services_to_buy
                  });
                }
              }}
              dataTip={this.props.errors}
              dataFor="tooltip-error-invoice_data"
            />
          </ButtonContainer>
        }

      </BuyInfoContainerView>
    );
  }

  render() {
    let isEditable = this.props.editable;

    return(
      <MainContainerView
        isEditable={isEditable}
      >
        { this.buildTitle() }
        { this.buildListHeader() }
        { this.buildListOfItems() }
        { this.buildBuyInfo() }
      </MainContainerView>
    );
  }

}

export default SubscriptionShoppingCarView;

////////// style

const MainContainerView: ComponentType<{ isEditable: boolean }>= styled.div`
  background-color: #1a202e;
  width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const TitleView = styled.div`
  color: white;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const ListOfItemsContainerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  max-height: 350px;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const ItemContainerView = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const ItemDescriptionContainerView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '24%' : '50%')};
  color: white;
`;

const ItemDescriptionNameView = styled.div`
  font-size: 15px;
  opacity: 65%;
`;

const ItemDescriptionPlanDescriptionView = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

const ItemDescriptionPricePlanView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '16%' : '30%')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
`;

const ItemDescriptionDeleteView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '10%' : '20%')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BuyInfoContainerView = styled.div`
  display: flex;
  flex-direction: column;
`;

const BuyInfoPriceContainerView = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
`;

const BuyInfoPriceTotalView = styled.div`
  font-size: 16px;
`;

const BuyInfoPriceView = styled.div`
  font-size: 18px;
`;

const QuantityItemContainer = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 59%;
  color: white;
  font-size: 12px;
  text-align: center;
`;

const QuantityItemTitle = styled.div`
  position: absolute;
  top: 0px;
  font-size: 16px;
  color: white;
  opacity: 59%;
`;

const QuantityItemPrice = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
`;

const QuantityItemInput = styled.input`
  align-items: center;
  background: #6a81ad;
  border: none;
  width: 70%;
  height: 30px;
  color: #ffffff;
  font-weight: bolder;
  text-align: center;
  font-size: 12px;
  border-bottom: 2px solid #35315a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const HeaderContainerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
`;

const DividerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;