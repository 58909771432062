import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateControl, validationErrors } from '../validate';
import ReactTooltip from 'react-tooltip';

class SelectInput extends Component {
  state = {
    touched: false,
    valid: undefined,
    validationErrors: [],
    onFocused: false,
    validate: this.props.validate,
    isHovered: false
  }

  componentDidMount() {
    if (this.props.value !== 0) {
      this.handleInput({ target: { name: this.props.name, value: this.props.value } })
    }
  }

  componentDidUpdate() {

    const { touched } = this.state
    const { onFocused } = this.props

    if (onFocused && !touched) {
      this.setState({ touched: true }, () => {
        this.isValid(this.props.value);
      })
    }
  }

  getOptions = () => {
    return this.props.items.map( (item) => {
      const { disabled = false } = item;
      return (
        <option key={item.id} value={item.id} disabled={disabled}>
        {item.description}
      </option>
      )
    })
  }

  getGroups = () => {
    return this.props.items.map( group => {
      return (
        <optgroup key={group.id} label={group.description}>
          {
            (group.items || []).map(i => {
              return (
                <option key={i.id} value={i.id}>
                  {i.description}
                </option>
              )
            })
          }
        </optgroup>
      )
    })
  }

  getDefaultOption = () => {

    if (this.props.hideDefaultOption !== undefined && this.props.hideDefaultOption === true) {
      return;
    }

    if (this.props.value === 0) {
      return <option key='0' value='0' />
    }
  }

  handleInput = (e) => {
    this.props.onChange(e);
    this.isValid(e.target.value);
  }

  isValid = (value = this.props.value) => {
    this.setState({onFocused: false})
    if (this.state.touched) {
      let result = validateControl(this.props.validate, value !== 0 ? value : '');
      this.setState({
        valid: result.validationResult.valid,
        validationErrors: result.validationResult.errors
      });
    }
  }

  render() {
    return (
      <>
        {
          this.props.extraInfo !== ' ' &&
          <ReactTooltip
            id={"tooltip-".concat(this.props.name)}
            clickable={true}
            delayHide={50}
            delayShow={50}
            delayUpdate={200}
            place={"bottom"}
            border={true}
            type={"light"}
          >
            {this.props.extraInfo}
          </ReactTooltip>
        }
        <div 
          data-tip
          data-for={this.props.extraInfo ? "tooltip-".concat(this.props.name) : ''}
          className={"select-input ".concat(this.props.className || '', this.state.valid === false ? ' invalid' : ' valid')}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          <label
            htmlFor={this.props.name}
            className={'label'.concat(this.state.onFocused || this.props.value ? ' focused' : '')}>
            {this.props.label}
          </label>
          {
            this.props.hideCaret 
            ? ''
            : 
            <img
              alt=""
              src="/images/select-arrow.svg"
              width={this.state.isHovered ? "24px" : "20px"}
              height={this.state.isHovered ? "26px" : "12px"}
              style={{
                display: 'block',
                position: 'absolute',
                right: '0%',
                top: '50%',
                transform: 'translateY(-50%)'
              }}
            ></img>
          }
          <select
            id={this.props.name}
            name={this.props.name}
            value={this.props.value}
            onChange={this.handleInput}
            className={"".concat(this.props.value ? 'has-input' : '')}
            onFocus={() => this.setState({ touched: true, onFocused: true })}
            onBlur={() => this.isValid()}
            disabled={this.props.disabled}
            style={this.props.hideCaret ? {} : {cursor: 'pointer'}}
          >
            {this.getDefaultOption()}
            {this.props.grouped ? this.getGroups() : this.getOptions()}
          </select>
          {
            this.state.valid === false &&
            <div className='text-input-invalid'>
              <img className='i-error' alt='' src='/images/red-exclamation.svg' />
              <p className="p-error">{this.state.validationErrors && this.state.validationErrors.length > 0 ? this.state.validationErrors[0] : validationErrors.required}</p>
            </div>
          }
        </div>
      </>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.validate !== prevState.validate) {
      return {
        touched: false,
        onFocused: false,
        validate: nextProps.validate
      }
    }
    return null;
  }
}

export default SelectInput;

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      description: PropTypes.string.isRequired
    }
  )).isRequired,
  validate: PropTypes.array,
  hideCaret: PropTypes.bool
}

SelectInput.defaultProps = {
  items: [],
  validate: [],
  grouped: false,
  hideCaret: false,
  onChange: () => { console.log('Not implemented yet!') }
}