import React from "react";
import { withRouter } from "react-router-dom";
import { View, ViewTitle } from '../../../shared/styled/view';
import styled from "styled-components";
import SubscriptionShoppingCarView from '../subscription/subscription-shopping-car-view';
import BillingDataView from "./billing-data-view";
import ConfirmDialog from "../../../shared/dialog/confirm-dialog";
import { isArray } from 'lodash';
import { connect } from "react-redux";
import {
  readEntities,
  getEntityItems,
  readEntity,
  partialEntity,
  createEntity
} from "../../../redux/api/";
import GenericDialog from "../../../shared/dialog/generic-modal";
import SelectInput from "../../../shared/controls/select-input";
import { getShoppingCartData, validateEmails, removeItemShoppingCart, removeShoppingCart, showSimpleFlashNotification } from "../../../shared/utils";

class BuyOrbibucksView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,

      itemsToBuy: getShoppingCartData(),
      iva: props.history.location.state.iva,
      exchange_rate: props.history.location.state.exchange_rate,
      currency_code: props.history.location.state.currency_code,
      billingActivated: '2',
      billUse: 0,
      paymentMethod: '15',

      info: {
        rfc: '',
        razon_social: '',
        postal_code: '',
        regimen: 0,
        email_billing: ''
      },

      rfc: '',
      razon_social: '',
      postal_code: '',
      regimen: 0,
      email_billing: '',

      buySuccess: false,
      errorMsg: '',
      coupon: null
    };
  }

  componentDidMount = () => {
    this.props.loadFormaDePago();
    this.props.loadUsoCfdi();
    this.props.loadTaxRegime();
    this.props.loadTipoFactor();
    this.props.loadImpuesto();
    this.props.loadTasaCuota();
    this.props.loadSATUnitCatalog();
    
    this.props.getInvoiceData({
      company_id: this.props.companyId,
    }, {
      alias: "get_invoice_data",
      onSuccess: (response) => {
        this.setInvoiceData(response);
      }
    });
    this.props.loadWalletInfo({
      company_id: this.props.companyId
    });
    this.props.getCoupons({
      company_id: this.props.companyId
    });
  }

  setInvoiceData = (response) => {
    try {
      const { data } = response;

      this.state.billUse = data.bill_use;

      this.state.rfc = data.rfc;
      this.state.razon_social = data.razon_social;
      this.state.email_billing = data.email;
      this.state.postal_code = data.postal_code;
      this.state.regimen = data.regimen_fiscal;
      this.state.billingActivated = data.billingActivated ? '1' : '2';

      this.state.info.billUse = data.bill_use;
      this.state.info.rfc = data.rfc;
      this.state.info.razon_social = data.razon_social;
      this.state.info.email_billing = data.email;
      this.state.info.postal_code = data.postal_code;
      this.state.info.regimen = data.regimen_fiscal;
      this.state.info.billingActivated = data.billingActivated ? '1' : '2';


      this.setState({});
    } catch(error) {
      console.log('Error en acceder a la info de invoice_data', error);
    }
  }

  changeEditable = (items) => {
    this.setState({
      itemsToBuy: items
    });
  };

  handleChange = (e) => { 
    const { name, value } = e.target;
    if (value !== null && value !== undefined && !isNaN(value)) {
      this.setState({ [name]: value}, () => {
        if (name === 'billingActivated' && `${value}` !== `${this.state.info.billingActivated}`) {
          this.state.info.billingActivated = value;
          this.updateInvoiceData();
        }
  
        if (name === 'billUse' && `${value}` !== `${this.state.info.billUse}`) {
          this.state.info.billUse = value;
          this.updateInvoiceData();
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value});
  }

  buildJsonToSend = () => {
    return({
      company_id: this.props.companyId,
      rfc: this.state.rfc,
      razon_social: this.state.razon_social,
      postal_code: this.state.postal_code,
      email: validateEmails(this.state.email_billing),
      regimen_fiscal: this.state.regimen,
      bill_use: `${this.state.billingActivated}` === '2' ? 0 : this.state.billUse,
      payment_method: this.state.paymentMethod,
      billingActivated: `${this.state.billingActivated}` === '1'
    });
  }

  updateInvoiceData = () => {
    this.props.updateInvoiceData(
      this.buildJsonToSend(), 
      {
        onSuccess: (response) => {
          this.state.showEditModal = false;
          this.setInvoiceData(response);
          showSimpleFlashNotification('Se guardaron los datos correctamente, se usarán para futuras facturas', null);
        }
      });
  }

  getInfoToShow = () => {
    let info = this.state.info;

    let tax_regime = this.props.taxRegimes.filter( item => `${item.id}` === `${info.regimen}` )

    if (tax_regime && tax_regime.length > 0) {
      info.regimen_fiscal = tax_regime[0].description;
    } else {
      info.regimen_fiscal = '';
    }

    return info;
  }

  getErrorsToBuy = () => {
    let errors = "";

    if (this.state.billingActivated === '1') {
      errors += String(this.state.billUse) === '0' ? "<b>Seleccione el uso de la factura<b/><br/>" : '';

      errors += String(this.state.rfc).length === 0 ? "<b>Ingresa el rfc<b/><br/>" : '';
      errors += String(this.state.razon_social).length === 0 ? "<b>Ingresa la razón social<b/><br/>" : '';
      errors += String(this.state.postal_code).length === 0 ? "<b>Ingresa el código postal<b/><br/>" : '';
      errors += String(this.state.regimen) === '0' ? "<b>Seleccione el regimen fiscal<b/><br/>" : '';
      errors += String(this.state.email_billing).length === 0 ? "<b>Ingresa algun correo electrónico<b/><br/>" : '';
    }

    if (errors !== "") {
      errors = `Por favor completa los siguientes campos:<br/>${errors}`
    }

    return errors;
  }

  buildData = () => {

    let regimen_fiscal_description = '';
    let bill_use_description = '';
    let payment_method_description = '';
    let regimen_fiscal_clave = '';
    let bill_use_clave = '';
    let payment_method_clave = '';

    let tax_regime = this.props.taxRegimes.filter( item => `${item.id}` === `${this.state.regimen}`);
    if (tax_regime && tax_regime.length > 0) {
      regimen_fiscal_description = tax_regime[0].description;
      regimen_fiscal_clave = tax_regime[0].clave;
    }

    let bill_use = this.props.usoCfdi.filter( item => `${item.id}` === `${this.state.billUse}`);
    if (bill_use && bill_use.length > 0) {
      bill_use_description = bill_use[0].description;
      bill_use_clave = bill_use[0].clave;
    }

    let payment_method = this.props.formaDePago.filter( item => `${item.id}` === `${this.state.paymentMethod}`);
    if (payment_method && payment_method.length > 0) {
      payment_method_description = payment_method[0].description;
      payment_method_clave = payment_method[0].clave;
    }

    return {
      general_data: {
        exchange_rate: this.state.exchange_rate,
        iva: this.state.iva,
        currency_code: this.state.currency_code
      },
      billing_data: {
        rfc: this.state.rfc,
        razon_social: this.state.razon_social,
        postal_code: this.state.postal_code,
        regimen_fiscal: this.state.regimen,
        regimen_fiscal_description,
        email: validateEmails(this.state.email_billing),

        clabe: this.props.walletInfo?.clabe || '',
        billingActivated: `${this.state.billingActivated}` === '1',
        bill_use: `${this.state.billingActivated}` === '2' ? 0 : this.state.billUse,
        bill_use_description: `${this.state.billingActivated}` === '2' ? '' : bill_use_description,
        payment_method: this.state.paymentMethod,
        payment_method_description,
        regimen_fiscal_clave,
        bill_use_clave,
        payment_method_clave,

        Impuestos: this.buildTaxes()
      }
    };
  }

  buildTaxes = () => {
    let tax = {
      Traslados: [
        {
          Impuesto: this.props.taxForDefault?.clave || '',
          TipoFactor: this.props.tipoFactorForDefault?.clave || '',
          TasaOCuota: this.props.tasaCuotaForDefault?.maximo || '0'
        }
      ]
    };

    return tax;
  }

  render() {

    return(
      <View>
        <ViewTitle>
          <div style={{flex: "1"}}>
            Realizar compra
          </div>
        </ViewTitle>

        <ContainerView>

          <BillingDataView
            company={this.props.company}
            info={this.getInfoToShow()}
            editAction={() => { this.setState({showEditModal: true}) }}
            billingActivated={this.state.billingActivated}
            onChange={this.handleChange}
            formaDePago={this.props.formaDePago}
            usoCfdi={this.props.usoCfdi}
            billUse={this.state.billUse}
            paymentMethod={this.state.paymentMethod}
            clabe={this.props.walletInfo?.clabe || ''}
            isAcceptLoading={this.props.isLoadingInvoiceData}
          />

          <SubscriptionShoppingCarView
            exchange_rate={this.state.exchange_rate}
            currency_code={this.state.currency_code}
            iva={this.state.iva}
            itemsToBuy={this.state.itemsToBuy}
            deleteItem={( item_id => {
              removeItemShoppingCart(item_id, () => {
                this.setState({ itemsToBuy: getShoppingCartData() });
              });
            })}
            editable={true}
            errors={this.getErrorsToBuy()}
            buyButtonDisabled={this.state.billingActivated === '1' && this.state.billUse === 0}
            isLoading={this.props.isLoadingbuyingOrbibucks}
            buyAction={(info) => {
              info.company_id = this.props.companyId;

              if (this.state.coupon) {
                info.coupon_id = this.state.coupon.id
              }

              info.data = this.buildData();
              info.sku_units = this.props.skuUnits;

              this.props.buyOrbibucks(info, {
                onSuccess: (result) => {
                  removeShoppingCart();
                  this.setState({buySuccess: true})
                },
                onError: (e) => {
                  this.setState({
                    errorMsg: e.response?.data?.error || "Ocurrió un error, verifica tu información"
                  });
                }
              });
              
            }}
            changeEditable={this.changeEditable}
            coupons={this.props.coupons}
            onCouponSelect={(coupon) => {
              this.setState({coupon});
            }}
            couponSelected={this.state.coupon}
          />

        </ContainerView>

        <ConfirmDialog
          open={this.state.showEditModal}
          isAcceptLoading={this.props.isLoadingInvoiceData}
          title="Datos de facturación"
          class='no-bottom-margin-2'
          contentObject={
            <ModalContainerView>
              <ModalNameFieldView>
                <ModalFieldView>{'RFC:'}</ModalFieldView>
                <ModalFieldView>{'Razón social:'}</ModalFieldView>
                <ModalFieldView>{'Código Postal:'}</ModalFieldView>
                <ModalFieldView>{'Regimen fiscal:'}</ModalFieldView>
                <ModalFieldView>{'Correos electronicos:'}</ModalFieldView>
              </ModalNameFieldView>

              <ModalInputFieldView>

                <ModalFieldView>
                  <TextInput
                    name="rfc"
                    type="text"
                    value={this.state.rfc}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="razon_social"
                    type="text"
                    value={this.state.razon_social}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="postal_code"
                    type="text"
                    value={this.state.postal_code}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                <SelectInput
                    label='Régimen Fiscal:'
                    value={parseInt(this.state.regimen)}
                    name='regimen'
                    items={this.props.taxRegimes}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="email_billing"
                    type="text"
                    value={this.state.email_billing}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

              </ModalInputFieldView>
            </ModalContainerView>
          }
          acceptText="Guardar"
          showCancel={true}
          cancelText="Cerrar"
          cancelButtonType="secondary"
          closeAction={() => {

            this.state.rfc = this.state.info.rfc;
            this.state.razon_social = this.state.info.razon_social;
            this.state.email_billing = this.state.info.email_billing;
            this.state.postal_code = this.state.info.postal_code;
            this.state.regimen = this.state.info.regimen;

            this.setState({showEditModal: false })
          }}
          acceptAction={() => {
            this.updateInvoiceData();
          }}
        />

        <GenericDialog
          open={this.state.buySuccess}
          title={'Orbibucks'}
          message={'Pedido creado exitosamente'}
          acceptAction={() => { this.props.history.go(-2); }}
          closeAction={() => { this.props.history.go(-2); }}
        />

        <GenericDialog
          open={this.state.errorMsg !== ''}
          title={'Error'}
          message={this.state.errorMsg}
          acceptAction={() => { this.setState({errorMsg: ''}) }}
          closeAction={() => { this.setState({errorMsg: ''}) }}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const formaDePago = getEntityItems(state, "COMPANIES.SAT.CFDI.FORMADEPAGO");
  const usoCfdi = getEntityItems(state, "COMPANIES.SAT.CFDI.USOCFDI");
  const taxRegimes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.REGIMENFISCAL").map(p => ({id: p.id, description: p.clave.concat('-', p.descripcion), clave: p.clave}));
  const isLoadingInvoiceData = state?.api['COMPANIES.INVOICEDATA']?.status?.isFetching || false;
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET"); 
  const coupons = (getEntityItems(state, "COMPANIES.COUPON") || []).filter( item => (item?.status?.id || 0) === 2);
  const isLoadingbuyingOrbibucks = state?.api['COMPANIES.WALLETMOVEMENTS.BUY']?.status?.isFetching || false;
  const impuesto = getEntityItems(state, "COMPANIES.SAT.CFDI.IMPUESTO");
  const tipoFactor = getEntityItems(state, "COMPANIES.SAT.CFDI.TIPOFACTOR");
  const tasaCuota = getEntityItems(state, "COMPANIES.SAT.CFDI.TASACUOTA");
  const skuUnits = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEUNIDAD").filter(u => u.clave === 'B17' || u.clave === 'A9' || u.clave === 'E48');

  let taxForDefault = null;

  for (var i = 0; i < (isArray(impuesto) ? impuesto : []).length; i++) {
    if (impuesto[i].descripcion === 'IVA') {
      taxForDefault = impuesto[i];
    }
  }

  let tipoFactorForDefault = null;

  for (var i = 0; i < (isArray(tipoFactor) ? tipoFactor : []).length; i++) {
    if (tipoFactor[i].descripcion === 'Tasa') {
      tipoFactorForDefault = tipoFactor[i];
    }
  }

  let tasaCuotaForDefault = null;

  let tasa_cuota = (isArray(tasaCuota) ? tasaCuota : []).filter(item => (item.rango_o_fijo === 'Fijo' && item.impuesto === 'IVA' && item.maximo.includes('0.16')));
  if (tasa_cuota && tasa_cuota.length > 0) {
    tasaCuotaForDefault = tasa_cuota[0];
  }

  return{
    companyId: state.globalCatalog.session.company.id,
    company: state.globalCatalog.session.company,
    formaDePago: (isArray(formaDePago) ? formaDePago : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`, clave: item.clave})),
    usoCfdi: (isArray(usoCfdi) ? usoCfdi : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`, clave: item.clave})),
    taxRegimes,
    isLoadingInvoiceData,
    walletInfo: walletInfo && walletInfo.length > 0 ? walletInfo[0] : null,
    isLoadingbuyingOrbibucks,
    coupons,
    taxForDefault,
    tipoFactorForDefault,
    tasaCuotaForDefault,
    skuUnits
  };
}

const mapDispatchToProps = (dispatch) => {
  return{
    getInvoiceData: (params, opt) => dispatch(readEntity("COMPANIES.INVOICEDATA", params, opt)),
    loadFormaDePago: () => dispatch(readEntities("COMPANIES.SAT.CFDI.FORMADEPAGO", {}, {prefix: 'orbisat.'})),
    loadUsoCfdi: () => dispatch(readEntities("COMPANIES.SAT.CFDI.USOCFDI", {}, {prefix: 'orbisat.'})),
    loadTaxRegime:  () => dispatch(readEntities("COMPANIES.SAT.CATALOGS.REGIMENFISCAL", {}, {prefix: 'orbisat.'})),
    updateInvoiceData: (params, opt) => dispatch(partialEntity("COMPANIES.INVOICEDATA", params, opt)),
    loadWalletInfo: (params, opt) => dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt)),
    buyOrbibucks: (params, opt) => dispatch(createEntity("COMPANIES.WALLETMOVEMENTS.BUY", params, opt)),
    getCoupons: (params, opt) => dispatch(readEntities("COMPANIES.COUPON", params, opt)),
    loadImpuesto: () => dispatch(readEntities("COMPANIES.SAT.CFDI.IMPUESTO", {}, {prefix: 'orbisat.'})),
    loadTipoFactor: () => dispatch(readEntities("COMPANIES.SAT.CFDI.TIPOFACTOR", {}, {prefix: 'orbisat.'})),
    loadTasaCuota: () => dispatch(readEntities("COMPANIES.SAT.CFDI.TASACUOTA", {}, {prefix: 'orbisat.'})),
    loadSATUnitCatalog: () => dispatch(readEntities("COMPANIES.SAT.CATALOGS.CLAVEUNIDAD", {}, {prefix: 'orbisat.'}))
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyOrbibucksView));

////////// style

const ContainerView = styled.div`
  display: flex; 
  align-items: center;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
`;

const ModalContainerView = styled.div`
  display: flex;
`;

const ModalNameFieldView = styled.div`
  width: 30%;
`;

const ModalFieldView = styled.div`
  height: 60px;
  width: auto;
  margin-top: 1px;
  color: #252E47;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ModalInputFieldView = styled.div`
  width: 70%;
`;

const TextInput = styled.input`
  background: white;
  border: none;
  width: 100%;
  height: 30px;
  color: black;
  font-size: 14px;
  border: 2px solid #AFAFB0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;